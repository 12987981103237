@import "variables";


.about-app-text{
  color: $color-ternary;
  margin-bottom: 100px;
  font-size: 16px;
  line-height: 150%;
}

.about-wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;


  @media screen and (max-width: $break-small) {
    width: 100%;
    flex-direction: column;
  }

  .profile-wrapper {
    width: 50%;
    max-width:  384px;
    margin-bottom: 20px;

    @media screen and (max-width: $break-small) {
      width: 100%;
      margin-bottom: 75px;
    }

    .profile-picture-wrapper {
      width: 100%;
      overflow: hidden;
      height: 350px;

      .profile-picture {
        height: 300px;
        border-radius: 3px;


      }
    }


    .person-name {
      margin-top: 15px;
      margin-bottom: 0px;
    }

    .person-role {
      margin-top: 5px;
      color: $color-primary;
      font-size: 20px;
    }

    .about-person-text {
      margin-top: 25px;
      font-size: 16px;
      line-height: 150%;
      color: $color-ternary;
    }

    .contact-section {
      align-items: center;
      display: flex;
      margin-top: 40px;

      .fb-button {
        margin-left: 2px;
      }
    }
  }
}
