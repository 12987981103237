@import "variables";


.ReactModal__Body--open {
  overflow: hidden;
}

.modal--width{
 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 9999;
  background: rgba(73, 59, 37, 0.5);

  .modal {
    margin: auto;
    position: relative;
    width: 690px;
    max-height: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    animation: slide-up 0.4s ease;

    &:focus {
      outline: none;
    }

    &--width{
      width: 803px !important;
    }

    // mobile (modal)
    @media screen and (max-width: $break-small) {
      max-width: calc(100vw - 45px);
      max-height: calc(100vh - 90px);
      margin-top: 80px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .top-bar-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 14px 20px 3px 34px;
      box-shadow: 0 4px 2px 2px rgba(186, 172, 152, 0.05);

      @media screen and (max-width: $break-small) {
        padding: 14px 20px 3px 14px;
        flex-direction: column;
      }

      .modal-header {
        white-space: nowrap;
        margin: 0;

        // mobile (modal-header)
        @media screen and (max-width: $break-small) {
          text-align: center;
        }
      }

      .search-input-form {
        border-color: #c4c4c4;
        height: 30px;
        align-items: center;
        padding: 2px;
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;
        margin: auto;
        display: flex;


        // mobile (search-input-form)
        @media screen and (max-width: $break-small) {
          margin-top: 15px;
        }

        .search-input {
          border: none;

          &:focus {
            outline: none;
          }
        }

        .search-icon {
          padding-right: 5px;
          color: $color-secondary;
        }
      }

      .close-icon-food-composer-modal {
        &:hover {
          cursor: pointer;
        }

        // mobile
        @media screen and (max-width: $break-small) {
          right: 15px;
          top: 15px;
          position: absolute;
        }
      }
    }


    .modal-content {
      padding: 0px 34px 20px 34px;
      margin-bottom: 2px;
      max-height: calc(100vh - 145px);
      display: flex;
      min-height: 300px;
      flex-direction: column;
      overflow-y: scroll;


      // mobile (modal-content)
      @media screen and (max-width: $break-small) {
        padding: 0px 8px 0px calc(100vw - calc(100vw - 8px));
        max-height: 60vh;
      }

      .food-chooser-text {
        margin: auto;
        text-align: center;
      }


      .food-chooser {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        justify-content: space-between;

        // mobile (food-chooser)
        @media screen and (max-width: $break-small) {
          margin-top: 5px;
        }

        .modal-header {
          text-align: center;
        }


        .item-wrapper {
          margin: 20px 0 0 0;

          // mobile (food-wrapper)
          @media screen and (max-width: $break-small) {
            margin: 32px 0px 0px 0px;
          }

          &:hover {
            cursor: pointer;
            box-shadow: $box-shadow;
          }

          .item-content {
            height: 100%;

            .item-weight-energy-wrapper {
              margin-bottom: 16px;
            }

            .item-photo {
              margin-top: 16px;
            }
          }
        }
      }

      .not-found-text {
        justify-content: center;
        height: 70%;
        display: flex;
        margin: auto;
        align-items: center;
        text-align: center;
        color: $color-ternary;
      }

      .load-more-button {
        width: max-content;
        margin: 15px auto;
      }

      .all-foods-loaded-info {
        margin: auto;
      }
    }
  }

}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}