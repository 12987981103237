#button-create-food-wrapper {
  display: flex;

  #button-create-food {
    margin: auto;
  }
}

.table-wrapper {
  overflow: hidden
}