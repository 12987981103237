$color-primary: #EE6543;
$color-secondary: #493B25;
$color-ternary: #594C37;
$color-quaternary: #E0DBD2;
$color-text: #FFFFFF;
$color-tip-label: #82A512;
$color-login: #F4C95B;

$font-family-roboto-slab: 'Roboto Slab', sans-serif;
$font-family-barlow-condensed: 'Barlow Condensed', sans-serif;

$font-size-main-header: 72px;

$break-small: 750px;
$break-medium: 990px;
$break-large: 1200px;

$footer-height: 390px;

$link-shadow: 0 5px 15px #c9c9c9;
$box-shadow: 0px 8px 32px rgba(185, 172, 152, 0.25), 0px 2px 8px rgba(185, 172, 152, 0.25);
$box-shadow-error2: 0px 8px 32px rgba(238, 101, 67, 0.75), 0px 2px 8px rgba(238, 101, 67, 0.5);
$box-shadow-error: 0px 8px 32px rgba(255, 0, 0, 0.35), 0px 2px 8px rgba(255, 0, 0, 0.35);


