@import "variables";

body {
  font-family: 'Roboto Slab', sans-serif;
  -webkit-font-smoothing: none;
  height: 100%;
  margin: 0 auto;
}


::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  -webkit-border-radius: 10px;
}

h1 {
  font-weight: 600;
  font-size: 64px;
  margin: 0;
  line-height: 100%;
  color: $color-secondary;
  font-family: $font-family-barlow-condensed;

  @media screen and (max-width: $break-small) {
    font-size: 48px;
    text-align: center;
  }
}

h2 {
  font-family: $font-family-barlow-condensed;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: $color-secondary;
}

h3 {
  font-family: $font-family-barlow-condensed;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: $color-ternary;
}

h4 {
  font-family: $font-family-barlow-condensed;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: $color-primary;
}

h5 {
  font-family: $font-family-barlow-condensed;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: $color-secondary;
}

h6 {
  font-size: 16px;
  margin: 0;
  color: #EE6543
}

.text{
  font-size: 16px;
  line-height: 32px;
  color: $color-ternary;
}

.link {
  color: $color-primary;
  text-decoration: underline;

  &:hover {
    text-shadow: $link-shadow;
    cursor: pointer;
  }
}

.loader {
  border: 5px solid #E0DBD2;
  border-top: 5px solid $color-primary;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: auto;
  animation: spin 2s linear infinite;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:focus{
  outline: none;
}

