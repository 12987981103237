@import "variables";

.other-backpack-food-wrapper {
  padding: 8px;
  display: flex;
  max-width: 254px;
  align-items: center;
  min-height: 110px;

  .other-backpack-food-photo {
    border-radius: 50%;
    margin-left: 0;
    margin-right: 22px;
    width: 55px;
    height: 55px;
  }

  .other-backpack-food-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .other-backpack-food-in-day-name {
      font-size: 14px;
      line-height: 42px;
      color: #B9AC98;
      font-style: italic;
    }

    .other-backpack-food-name {
      color: $color-ternary;
    }
  }
}

.bottom-border {
  border-bottom: 1px solid #F0EEE9;
}



