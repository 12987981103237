@import "variables";

.login-item-wrapper {
  display: flex!important;
  align-items: center;

  .google-login-text {

  }

  .google-login-logo {
    height: 18px;
    margin-right: 7px;

    @media screen and (max-width: $break-small) {
      margin-right: 1rem;
      height: 1.5rem;
    }
  }
}