@import "variables";

.footer {
  vertical-align: middle;
  display: flex;
  min-height: $footer-height;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  max-width: 100% !important;
  margin-bottom: -8px;
  text-align: center;

  .footer-background-wrapper {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
  }

  .footer-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile
    @media screen and (max-width: $break-small) {
      min-height: auto;
      flex-direction: column;
      width: 100%;
      padding: 0;
    }

    .footer-links-wrapper {

      .footer-links-part-1 {
        margin-top: 25px;

        // mobile
        @media screen and (max-width: $break-small) {
          display: flex;
          flex-direction: column;
        }

        .footer-link-1 {
          text-decoration: none;
          color: #FFFFFF;
          margin-left: 25px;

          @media screen and (max-width: $break-small) {
            margin-left: 0px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .footer-links-part-2 {
        margin-top: 17px;
        color: #B9AC98;
        display: flex;
        justify-content: center;

        // mobile
        @media screen and (max-width: $break-small) {
          flex-direction: column;
        }

        .footer-separator {
          margin-left: 4px;
          margin-right: 4px;
          color: #B9AC98;

          // mobile
          @media screen and (max-width: $break-small) {
            display: none;
          }
        }
      }


      .footer-links-part-3 {
        margin-top: 7px;

        .email-link {
          color: #FFFFFF;
          text-decoration: none;
        }
      }

      .footer-link {
        color: #B9AC98;
        margin-bottom: 8px;
        text-decoration: none;
      }


    }


    .social-buttons {
      // mobile
      @media screen and (max-width: $break-small) {
        display: flex;
        justify-content: flex-end;
        margin-right: 70px;
        margin-top: 25px;
        width: 100%;
        flex-direction: row;
      }
    }


  }

  // mobile
  @media screen and (max-width: $break-small) {
    width: 100%;
    padding: 25px 0 25px 0;
    min-height: 150px;
    background: #493B25;
  }


}


