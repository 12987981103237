@import "variables";

.button {
  background: $color-primary;
  border-style: none;
  border-radius: 4px;
  display: inline-block;

  font-family: $font-family-barlow-condensed;
  color: $color-text;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 12px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background: darken($color-primary, 5%);
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: 0;
  }

}

.button-circle {
  border-radius: 50%;
  padding: 0;
  background: transparent;
  border-style: solid;
  border-color: $color-primary;
  color: $color-primary;
  font-family: $font-family-roboto-slab;
  width: 40px;
  height: 40px;
  border-width: 0.04rem;
  font-weight: 200;
  margin-left: 10px;

  &:focus {
    outline: 0;
  }

  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background: lighten($color-primary, 35%);
  }


  .button-icon {
    padding-bottom: 1px;
  }
}


.social-button {
  width: 40px;
  margin-left: 24px;
  height: 40px;
  padding: 0;

  .social-logo {
    padding: 10px;
  }

  @media screen and (max-width: $break-small) {
    margin-left: 25px;
  }
}

.button-like {
  color: grey !important;
  border-color: transparent !important;

  &:hover {
    box-shadow: none;
    color: $color-primary !important;
    background: transparent;
  }

  &--disabled {
    @extend .button-like;
    color: grey !important;

    &:hover {
      color: grey !important;
      cursor: initial;
    }

    &:active {
      transform: none;
    }
  }
}


.button-unlike {
  color: $color-primary !important;
  border-color: transparent !important;

  &:hover {
    box-shadow: none;
    color: grey !important;
    background: transparent;
  }

  &--disabled {
    @extend .button-like;
    color: $color-primary !important;

    &:hover {
      color: $color-primary !important;
      cursor: initial;
    }

    &:active {
      transform: none;
    }
  }
}

.button-disabled {
  background: grey !important;

  &:hover {
    box-shadow: none;
  }

  &:active {
    transform: none;
  }
}
