@import "variables";

.item-wrapper {
  flex-direction: column;
  display: flex;
  margin-bottom: 3px;
  margin-top: 3px;
  width: 185px;
  background: #FFFFFF;
  border: 1px solid #E0DBD2;
  min-height: 288px;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 16px;

  // tablet
  @media screen and (max-width: $break-medium) {
    margin-left: 8px;
  }

  // mobile
  @media screen and (max-width: $break-small) {
    width: 100%;
    margin: 20px 0 0 0;
  }

  &--width {
    // pc
    @media screen and (min-width: $break-medium) {
      width: 206px !important;
    }
  }

  .top-line-wrapper {
    margin-left: 10px;
    margin-right: 5px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-line-text {
      font-size: 14px;
      line-height: 42px;
      color: #B9AC98;
      font-style: italic;

    }

    .remove-change-wrapper {
      margin-top: 9px;
      margin-right: 4px;

      .close-icon {
        border-radius: 50%;
        padding: 4px;

        &:active {
          transform: translateY(1px);
        }

        &:hover {
          background-color: #c4c4c4;
          cursor: pointer;
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .item-name {
    margin-top: auto;
    color: #493B25;
    margin-bottom: auto;

    // mobile (food-name)
    @media screen and (max-width: $break-small) {
      margin-bottom: 30px
    }
  }
  .item-content {
    flex-direction: column;
    display: flex;
    height: 79%;
    justify-content: space-between;
    margin: 0 20px 20px;


    .item-photo {
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5px;
      width: 112px;
      height: 112px
    }

    .item-choose-img {
      margin: auto;
    }



  }
  .item-change {
    font-size: 14px;
    color: $color-primary;

    &:hover {
      cursor: pointer;
      text-shadow: $link-shadow
    }
  }


}

.item-weight-energy-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 28px;
  color: #B9AC98;
  align-items: center;

  .item-energy {

  }

  .energy-weight-separator {
    width: 3px;
    height: 3px;
    background-color: #B9AC98;
    border-radius: 1px;
  }

  .item-weight {

  }
}

.item-photo {

}