@import "variables";
@import "modalBase";

.confirm-modal {
  @extend .modal-base;

  .confirm-modal-button-wrapper {
    justify-content: space-around;
    display: flex;


    .button-confirm-modal-positive {
      background-color: #ff4242 !important;
    }

    .button-confirm-modal-negative {
      background-color: #29ba3c !important;
    }

  }

}

// Additional content(s)
.my-backpacks-delete-modal-additional-content {
  margin-bottom: 22px;
  color: $color-secondary;
}