@import "variables";

.loader-wrapper {
  overflow: hidden;

  .food-composer-modal-loader {
    position: absolute;
    right: 55px;
    top: 23px;

    // mobile
    @media screen and (max-width: $break-small) {
      position: absolute;
      right: initial;
      top: initial;
    }
  }
}
