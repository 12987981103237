@import "variables";

.name-request-form {
  margin-bottom: 30px;


  .label-value-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .field-label {
      line-height: 36px;
      text-align: center;
      vertical-align: middle;
      color: $color-secondary;
    }

    .field-value {
      margin-left: 10px;
      border-color: #c4c4c4;
      height: 30px;
      align-items: center;
      padding: 2px;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;


      &:focus {
        outline: none;
      }
    }
  }

  .name-request-input-field-error-text {
    text-align: center;
    color: $color-primary;
  }
}