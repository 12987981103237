.items-filter {
  justify-content: center;
  display: flex;
  margin-top: 16px;
  margin-bottom: 10px;

  .field-label {
    color: $color-ternary;
  }

  .field {
    margin-left: 10px;
  }
}
