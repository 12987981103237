@import "variables";

.tip {
  margin: 3px 3px 30px;

  // pc
  &--three-side-by-side {
    @media screen and (min-width: $break-large) {
      width: 33%;
    }
  }

  .label {
    background-color: $color-tip-label;
    color: $color-text;
    font-size: 20px;
    display: inline-flex;
    padding: 4px 15px;
    border-radius: 1px;
  }

  .tip-text {
    font-size: 20px;
    line-height: 140%;
    margin-top: 24px;
  }
}