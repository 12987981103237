@import "variables";

.tips-section-wrapper {
  margin-top: 300px;
  min-height: 393px;

  // tablet
  @media screen and (max-width: $break-medium) {
    margin-top: 210px;
  }

  .background-image {
    position: absolute;
    margin-top: -100px;
    margin-left: -190px;
    z-index: -1;
  }

  .main-header {
    margin: 0;
    display: inline-flex;
  }

  .sub-header {
    margin: 15px 0 0 0;
  }

  .button-tips {
    float: right;
    padding-left: 25px;
    margin-top: 25px;
    padding-right: 25px;
  }

  .tips-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    
    // mobile
    @media screen and (max-width: $break-small) {
      flex-wrap: wrap
    }

  }

}

