@import "variables";

.menu-chooser {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: $color-text;
  border-radius: 4px;
  border-width: 0;
  min-height: 80px;
  border-style: solid;
  margin: auto;

  // tablet
  @media screen and (max-width: $break-large) {
    width: 60%;
  }

  // mobile
  @media screen and (max-width: $break-small) {
    margin-top: 50px;
    width: 100%;
  }

  &--shadow {
    box-shadow: $box-shadow
  }

  .form-selections-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: calc(100% - 135px);

    // tablet and mobile
    @media screen and (max-width: $break-large) {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      align-items: center;
      max-width: inherit;
    }

    .form-selection-section-wrapper {
      align-items: center;
      padding-bottom: 1px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 5px;

      .checkbox {
        margin-left: 20px;
      }

      .checkbox-label {
        color: $color-ternary;
        margin-left: 20px;
      }

      .form-value-label {
        color: $color-primary;
        display: inline-flex;
        margin-left: 10px;
        width: 10px;
      }

      .field-label {
        margin-left: 20px;
        color: $color-ternary;

        // tablet and  mobile
        @media screen and (max-width: $break-large) {
          margin: auto;
        }
      }

      .form-difficulty-img {
        margin-left: 10px;
        padding-bottom: 5px;

        &:hover {
          cursor: pointer;
        }

        &:active {
          transform: translateY(1px);
        }
      }

      // mobile
      @media screen and (max-width: $break-small) {
        margin-top: 10px;
      }
    }
  }


  .divider {
    background-color: $color-quaternary;
    width: 1px;
    margin-left: 25px;
    height: auto;

    // mobile
    @media screen and (max-width: $break-large) {
      display: none;
    }
  }

  .button-submit {
    margin-left: auto;
    height: auto;
    width: 180px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    // tablet and mobile
    @media screen and (max-width: $break-large) {
      width: 100%;
      margin-top: 10px;
      border-radius: inherit
    }
  }
}