@import "variables";

.weight-in-time-wrapper {
  text-align: center;

  .weight-in-time-chart-title {
    color: $color_secondary;
    font-weight: 600;
    font-size: 22px;
    margin: 0;
  }

  .chart-legend {
    display: flex;
    width: fit-content;
    margin: auto;

    .legend-line-wrapper {
      display: flex;
      margin-right: 15px;

      .legend-line {
        margin-top: auto;
        margin-bottom: auto;
        width: 112px;
        border-radius: 4px;
        height: 4px;
        margin-right: 25px;
      }

      .legend-line-header {
        margin: 0;
      }


      .without-water-color {
        background-color: #EE6543;
      }

      .with-water-color {
        background-color: #F4C95B;
      }


    }
  }
}

