@import "variables";

.inspire-leaves-img {
  left: 100px;
  margin-top: 400px;
  position: relative;

  // mobile
  @media screen and (max-width: $break-small) {
   margin-top: 50px;
  }

  // tablet
  @media screen and (max-width: $break-large) {
    margin-top: 100px;
  }
};

.sign-post-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 50px;

  // tablet
  @media screen and (max-width: $break-medium) {
    margin-right: 0;
  }

  // mobile
  @media screen and (max-width: $break-small) {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }

  .main-header-wrapper {

    // tablet
    @media screen and (max-width: $break-medium) {
      width: 100vw;
      margin-bottom: 40px;
    }

    // mobile
    @media screen and (max-width: $break-small) {
      width: auto;
    }


    .main-header-part-1 {
      margin: 0;
    }

    .main-header-part-2 {
      width: min-content;
      min-width: 180px;
      margin: 0;

      // mobile
      @media screen and (max-width: $break-small) {
        width: auto;
      }

      // tablet
      @media screen and (max-width: $break-medium) {
        width: auto;
      }
    }
  }

  .sing-post {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    // mobile
    @media screen and (max-width: $break-small) {
      margin-top: 50px;
      text-align: center;
    }

    .img {
      height: 120px;

      // mobile
      @media screen and (max-width: $break-small) {
        margin: auto;
      }
    }

    .header {
      margin-bottom: 0;
      margin-top: 20px;
    }

    .sing-post-text {
      margin: 30px 0 35px 0;
    }

    .button-singPost {
      margin-top: auto;
      width: 80%;
    }

  }


}


