@import "variables";

.slider {
  border: 1px solid #493B25;
  box-sizing: border-box;
  height: 16px;
  border-radius: 15px;

  .slider-value {
    height: 14px;
    margin-top: -1px;
    margin-left: -1px;
    background: mediumvioletred;
    border: 1px solid $color-secondary;
    border-radius: 15px;

  }

  &--weight-color {
    background-color: #F4C95B !important;
  }

  &--energy-color {
     background-color: #EE6543 !important;
   }

  &--water-color {
    background-color: #84c1ff !important;
  }


}