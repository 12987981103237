@import "variables";

.our-blog-wrapper {

  .month-header {
    width: 100%;
    color: #B9AC98;
    font-size: 20px;
  }

  #load-more-blog-post-button {
    display: flex;
    margin-right: auto;
    margin-left: auto;

    // mobile (modal)
    @media screen and (max-width: $break-small) {
      margin-bottom: 80px;
    }
  }
}

