@import "variables";

.page {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  .background-page-wrapper {
    left: 0;
    top: 0;
    position: absolute;
    overflow: hidden;
    z-index: -2;
    width: 100%;
    text-align: center;
  }
}


.content, .footer, .nav, .footer-content {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $break-small) {
    max-width: $break-small - 30px;
  }

  @media (min-width: $break-medium) {
    max-width: $break-medium - 30px;
  }

  @media (min-width: $break-large) {
    max-width: $break-large - 30px;
  }
}

.content {
  @extend .content;
  padding-bottom: $footer-height;

  @media (max-width: 750px) {
    margin: 90px 25px 0 25px;
  }
}

.footer {
  overflow: hidden;
}