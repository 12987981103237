@import "variables";

.modal-base {
  margin: auto;
  position: relative;
  border-radius: 8px;
  background-color: #FFFFFF;
  animation: slide-up 0.4s ease;
  width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  &:focus {
    outline: none;
  }

  .modal-base-top-bar-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .modal-base-modal-header {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .modal-base-close-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}