@import "variables";

.header-section-wrapper {
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: space-between;
  min-height: 221px;

  // mobile
  @media screen and (max-width: $break-small) {
    flex-direction: column;
  }

  .headers-wrapper {
    .main-header {
      max-width: 600px;
      font-family: $font-family-barlow-condensed;
      font-style: normal;
      display: block;
      color: $color-text;
      font-weight: 600;
      font-size: $font-size-main-header;
      line-height: 100%;
    }

    .sub-header {
      max-width: 470px;
      color: $color-text;
      display: block;
      margin-top: 25px;
      font-size: 20px;
    }
  }


  .highlights-wrapper {
    justify-content: space-between;
    display: flex;
    margin-right: 60px;
    flex-direction: column;

    .highlight-item {
      display: flex;
      align-items: center;
      margin-top: 40px;

      .highlight-item-icon-wrapper{
        height: 44px;
        width: 44px;
        background-color: #F4C95B;
        border-radius: 4px;

        .highlight-item-icon {
          padding: 11px;
        }
      }

      .highlight-item-text {
        margin-left: 23px;
        font-size: 20px;
        line-height: 140%;
        color: $color-text;

      }
    }
  }

}

