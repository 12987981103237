@import "variables";

.composer {

  .summery-wrapper {
    display: flex;
    margin-top: 80px;
    margin-bottom: 80px;
    justify-content: space-between;

    // tablet
    @media screen and (max-width: $break-medium) {
      flex-direction: column;
      margin-top: 35px;
      margin-bottom: 15px;
    }

    .summary-header {
      margin-top: 8px;
      margin-bottom: 8px;
      white-space: nowrap;
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .info-line {
        display: flex;
        align-items: center;
        min-width: 250px;

        .summery-img{
          height: 24px;
        }

        .summery-sub-header {
          color: $color-secondary;
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
          margin-left: 12px;
        }

        .value {
          color: $color-secondary;
          font-size: 16px;
          margin-left: auto;

          @media screen and (max-width: $break-small) {
            margin-left: auto;
          }
        }
      }

      // mobile (info-wrapper)
      @media screen and (max-width: $break-small) {
        margin-top: 10px;
      }
    }

  }

}