@import "variables";

.table-wrapper {
  padding-top: 100px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 1px;
  overflow: hidden;

  .button-load-next {
    margin: 50px auto;
  }

  .table {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    color: $color-secondary;

    th {
      color: #B9AC98;
      font-weight: 100;
      font-size: 14px;
      line-height: 28px;
    }

    .tr-bordered {
      border-bottom: 1px solid #E0DBD2;
    }

    tr {
      height: 110px;

      @media screen and (max-width: $break-small) {
        height: initial;
      }

      td {

      }

      .cell-classic {
        width: 13%;
      }

      .cell-info {
        padding-right: 10px;

        h5 {
          margin: 0 0 10px 0;
        }

        .info-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #827258;

          .td-trek-difficulty-img {
            padding-bottom: 3px;
          }
        }
      }

      .cell-toggle {
        width: 5%;
        text-align: right
      }

      .star {
        width: 22px;
        height: 22px;
      }
    }

    .tr-header {
      border: none;
    }

    .tr-opened {
      border: none;
    }
  }

  // Responsive
  @media screen and (max-width: $break-small) {
    table, thead, tbody, th, td, tr {
      display: block;
    }

    .tr {
      border: 1px solid #E0DBD2;
      margin-bottom: 15px;
      padding: 8px;
      border-radius: 3px;
    }

    .cell-info {
      text-align: center;

      .info-wrapper {
        width: inherit !important;
        margin-bottom: 0 !important;
      }


      .h5 {
        margin-top: 10px;
        margin-bottom: 15px;
      }
    }

    .cell-classic {
      width: inherit !important;
      margin-top: 9px;

      .stars-wrapper {
        justify-content: center;
      }
    }

    .cell-toggle {
      width: inherit !important;
    }


    .stars-wrapper {
      display: flex;
    }
  }
}

// *** TABLE RESPONSIVE ***
.tr-opened-data {
  width: 100%;

  .td-opened-data-content-wrapper {

    .table-menus-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: $break-small) {
        align-items: center;
        flex-direction: column;
      }

      .button-slide {
        @media screen and (max-width: $break-small) {
          display: none;
        }
      }


      .table-menu-display {
        display: flex;
        margin: 10px;
        min-width: 239px;
        flex-direction: column;
        border: 1px solid #E0DBD2;
        border-radius: 8px;
        padding: 25px 35px;
        background: #FFFFFF url("../assets/day-header-background-mobile.svg") no-repeat;
        background-position-y: -130px;

        @media screen and (max-width: $break-small) {
          margin: 15px 0 15px 0;
          max-width: 282px;
          min-width: calc(100vw - 90px);
        }

        // Header (name, weight, energy)
        .table-day-header-wrapper {
          display: flex;
          flex-direction: column;

          .table-day-header-label {
            margin-bottom: 24px;
            margin-top: 0;
          }

          .table-day-info-wrapper {
            display: flex;

            .table-value-field {
              font-size: 14px;
              margin-left: 10px;
              margin-right: 16px;
              color: $color-secondary;
            }
          }
        }

        .table-foods-day-wrapper {
          margin-top: 50px;
        }
      }
    }
  }
}

