@import "variables";

.page-header-wrapper {
  margin-top: 150px;
  margin-bottom: 75px;
  justify-content: space-between;
  display: flex;
  align-items: center;

  // mobile
  @media screen and (max-width: $break-small) {
    display: block;
    margin-top: 0;
  }

  .inspire-section-wrapper {

    .inspire-header {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      font-family: $font-family-barlow-condensed;
      color: $color-secondary;
    }

    .links-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .link {
      margin-right: 10px
    }
  }
}