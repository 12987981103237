@import "variables";

.checkbox {


  .checkbox-img {
    width: 18px;
    height: 18px;
    margin-top: 3px;
  }

  &:hover {
    cursor: pointer;
  }
}