@import "variables";

.nutrient-count-form {
  margin: auto;

  .nutrient-count-input {
    text-align: center;
    height: 36px;
    width: 156px;
    margin-top: 19px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #493b25;
    font-family: "Roboto Slab", sans-serif;
    border: 1px solid #e0dbd2;

    &:focus {
      box-shadow: $box-shadow;
    }
  }

  .nutrient-count-input.input-invalid {
    box-shadow: $box-shadow-error;
  }

  .unit-wrapper {
    position: relative;
    top: -31px;
    left: 110px;
    text-align: left;

    .unit {
      color: $color-secondary;
    }
  }
}
