@import "variables";

.login-color {
  color: $color-login !important;
}

.nav {
  background: transparent;
  display: flex;
  border: 0;
  height: 160px;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  text-align: center;

  // tablet
  @media screen and (max-width: $break-medium) {
    justify-content: space-between;
  }

  .logo-wrapper {
    margin-left: 0 !important;

    .logo {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .item {
    margin-left: 32px;
    color: $color-text;
    text-decoration: none;

    // tablet
    @media screen and (max-width: $break-medium) {
      margin-left: 0;
    }

    .caret-down {
      margin-left: 4px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .active {
    color: $color-primary;
  }

  .right {
    margin-left: auto;

    // tablet
    @media screen and (max-width: $break-medium) {
      margin-left: 0;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    width: 118px;
    padding: 5px 10px 10px 10px;
    position: absolute;
    background-color: rgba(89, 76, 55, 0.5);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    .profile-item {
      margin: 5px 0 0 0;
    }
  }

  .dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  // mobile
  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.navbar-background-wrapper {
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  width: 100%;

  // mobile
  @media screen and (max-width: $break-small) {
    height: 100%;
  }

  .navbar-background-img {
    height: 313px;
  }
}

.nav-mobile {
  width: 100%;
  padding: 20px;
  position: fixed;
  top: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 8;
  transition: top 0.2s ease 0s;

  .head-line {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;

    .menu-bars {
      color: $color-text
    }

    .navbar-toggler {
    }

    .logo {
      margin-right: 40%;
    }
  }


  .menu-body {
    padding-top: 15px;
  }

  .item {
    padding-right: 10px;
    padding-bottom: 5px;
    display: block;
    color: $color-text;
    width: 100%;
    font-size: 22px;
    text-decoration: none;

    svg {
      width: 1.5rem;
      margin-right: 1rem;
    }
  }

  .primary-color {
    color: $color-primary;
  }

  &--hidden {
    top: -68px;
  }

  // pc
  @media screen and (min-width: $break-small) {
    display: none;
  }


}









