.blog-post-detail-wrapper {

  .blog-post-detail-created-time {
    color: #827258;
    margin-top: 20px;
    margin-bottom: 90px;
  }

  .blog-post-detail-image{
    width: 100%;
    border-radius: 3px;
  }

  .blog-post-detail-text {
    margin-top: 40px;
  }

  .no-bottom-margin{
    margin-bottom: 0!important;
  }

  .blog-post-detail-created-time{

  }
}

