@import "variables";

.composer-line {
  position: relative;
  overflow: hidden;
  margin-right: 4px;
  margin-top: 80px;
  display: flex;
  width: fit-content;
  background: #ffffff url("../assets/day-header-background.svg") no-repeat;
  background-size: auto 100%;
  border-radius: 4px;

  // mobile
  @media screen and (max-width: $break-small) {
    flex-direction: column;
    margin: 35px 0 0;
    width: 100%;
    background: #ffffff url("../assets/day-header-background-mobile.svg") no-repeat;
    background-size: 100%;
  }

  .composer-line-header-wrapper {
    width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 48px 20px 40px 25px;
    min-width: 85px;

    // mobile (day-header-wrapper)
    @media screen and (max-width: $break-small) {
      margin: 15px auto 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }


    .sub-summary-wrapper {
      // mobile
      @media screen and (max-width: $break-small) {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        width: 60%;
      }

      .sub-summary {

        .sub-summary-value {
          font-size: 20px;
          line-height: 140%;
          color: $color-secondary;
          margin-bottom: 20px;
        }
      }
    }
  }

  .composer-line-content-wrapper {
    display: flex;
    margin: 20px 20px 20px 0;
    flex-wrap: wrap;

    // tablet
    @media screen and (max-width: $break-medium) {
      margin: 20px 0 20px 0;
    }

    // mobile
    @media screen and (max-width: $break-small) {
      margin-left: 32px;
      margin-right: 32px;
      margin-top: 0;
    }
  }
}

// Special styling
.minWidth195px {
  min-width: 195px !important;
}