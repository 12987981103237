@import "variables";

.blog-section-wrapper {
  margin-top: 300px;

  // tablet
  @media screen and (max-width: $break-medium) {
    margin-top: 200px;
  }

  // mobile
  @media screen and (max-width: $break-small) {
    margin-top: 100px;
  }

  .main-header {
    display: inline-flex;
  }

  .button-our-blog {
    float: right;
    padding-left: 25px;
    margin-top: 15px;
    padding-right: 25px;
  }

}