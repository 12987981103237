@import "variables";

.blog-post-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 70px;

  .month-header {
    margin-bottom: 44px;
  }

  .blog-post {
    width: 350px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;

    .image {
      width: 100%;
      border-radius: 2px;
      height: auto;

      &:hover {
        cursor: pointer;
      }
    }

    .title {
      margin: 30px 0 0 0;

      &:hover {
        cursor: pointer;
      }
    }

    .label-link-wrapper {
      margin-top: 12px;
      line-height: 24px;
      display: flex;

      .author-label {
        color: #827258;
        font-size: 16px;
      }

      .created-date-label {
        font-size: 16px;
        margin-left: 20px;
        color: #827258;
      }

      .link-read-more {
        margin-left: 30px;
        text-decoration: none;
      }
    }
  }
}