@import "variables";


.download-buttons-outer-wrapper {
  margin-top: 40px;
  margin-bottom: 120px;
  position: relative;
  height: 45px;
  display: flex;


  .horizontal-divider {

    border: 1px solid #E0DBD2;
    z-index: -1;
    width: 100%;
    top: 23px;
    position: absolute
  }

  .download-buttons-inner-wrapper {
    display: flex;
    margin: auto;
    width: 341px;


    @media screen and (max-width: $break-small) {
      display: flex;
      flex-direction: column;
      height: initial;
    }

    #button-download-menu {
      font-size: 20px;
    }

    #button-download-menu.float-position {
      position: fixed;
      bottom: 20px;
      right: 20px;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
      animation-delay: 0s;
      animation-direction: normal;
      animation-duration: 0.7s;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
      animation-name: dockRight;

      @media screen and (max-width: $break-small) {
        display: flex;
        flex-direction: column;
      }
    }

    #button-download-menu.fixed-position {
      animation-name: dockCenter;
      animation-delay: 0s;
      animation-direction: normal;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;

      @media screen and (max-width: $break-small) {
        margin-top: 15px;
        animation-name: none;
      }
    }

    // mobile (download-buttons-wrapper)
    @media screen and (max-width: $break-small) {
      margin-top: 15px;
    }

    .button-save-publish {
      margin-right: 20px;

      @media screen and (max-width: $break-small) {
        margin-right: initial;
      }
    }


  }


}


@keyframes dockCenter {
  0% {
    -webkit-transform: translate(35vw, -100px);
    transform: translate(35vw, -100px);
  }
  100% {
    -webkit-transform: translate(0px, 0);
    transform: translate(0px, 0);
  }
}

@keyframes dockRight {
  0% {
    -webkit-transform: translate(-35vw, 100px);
    transform: translate(-35vw, 100px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
